import React, { useEffect, useState } from 'react'
import { Container,Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getInscription, editInscription } from '../../actions/activities/inscriptionsActions'
import { getGroupsByClub } from '../../actions/activities/groupsActions'
import { getPaymentsInscription, editPaymentQuantity, editPaymentExternalStatus, createManualPayment } from '../../actions/payments/paymentActions'
import { parseISO, format } from 'date-fns'
import Loader from '../design/Loader/Loader'
import { getCommissionByClub } from '../../actions/settings/commissionsActions'
import ManualPayment from '../Payments/ManualPayment'
import ReturnPayment from '../Payments/ReturnPayment'
import paycometErrors from '../../components/paycometError.json'
import CreateManualPayment from '../Payments/CreateManualPayment'

export default function InscriptionSummary({usertype, typeform, inscriptionselected, closeModal}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()

	const {inscription, loadinginscription} = useSelector(state=>state.inscriptions)
	const {groups} = useSelector(state=>state.groups)
	const {payments, retryStatus, returnStatus, createManualStatus} = useSelector(state=>state.payment)
	const {commission} = useSelector(state=>state.commissions)

    const [manualPaymentSelected, setManualPaymentSelected] = useState(null)
    const [paymentToReturnSelected, setPaymentToReturnSelected] = useState(null)
    const [createPaymentInscription, setCreatePaymentInscription] = useState(null)

    const [externalInitialPayment, setExternalInitialPayment] = useState(false)
    const [seasonPayments, setSeasonPayments] = useState()
    const [registerPaymentQuantity, setRegisterPaymentQuantity] = useState(null)
    const [registerPaymentQuantityTax, setRegisterPaymentQuantityTax] = useState(null)
    const [quantities, setQuantities] = useState([])
    const [quantitiesTax, setQuantitiesTax] = useState([])
    const [externalPayments, setExternalPayments] = useState([])
    const [selectedHorary, setSelectedHorary] = useState('')
    const [selectedGroup, setSelectedGroup] = useState('')

    useEffect(()=>{
        if(inscriptionselected && usertype){
            dispatch(getInscription(inscriptionselected))
            dispatch(getPaymentsInscription(inscriptionselected, usertype))
        }
    //eslint-disable-next-line
    }, [dispatch, inscriptionselected, usertype, typeform])

    useEffect(()=>{
        if(inscription){
            dispatch(getGroupsByClub(inscription?.club?._id))
            dispatch(getCommissionByClub(inscription?.club?._id))

            if(inscription?.rawInitialPayment > 0){
                setRegisterPaymentQuantity(inscription?.rawInitialPayment)
                setRegisterPaymentQuantityTax((inscription?.initialPayment-inscription?.rawInitialPayment).toFixed(2))
            }else{
                setRegisterPaymentQuantity(0)
                setRegisterPaymentQuantityTax(0)
            }
            setExternalInitialPayment(inscription?.externalInitialPayment || false);

            if(inscription?.seasonPayments){
                setSeasonPayments(inscription?.seasonPayments)
            }
            setQuantities(inscription?.seasonPayments?.map(seasonPayment => seasonPayment.rawPrice))
            setQuantitiesTax(inscription?.seasonPayments?.map(seasonPayment => (seasonPayment.price-seasonPayment.rawPrice).toFixed(2)))
            setExternalPayments(inscription?.seasonPayments?.map(seasonPayment => {
                return seasonPayment.externalPayment || seasonPayment.externalPaid
            }))
        }
    //eslint-disable-next-line
    }, [inscription])

    useEffect(() => {
        if (retryStatus) {
            switch (retryStatus.message) {
                case "retryPaymentSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.retryPaymentSuccess')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                case "retryPaymentClientError":
                    const error = paycometErrors?.find(err => err.code === retryStatus?.typeError.toString())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        html: `${t('payments.retryPaymentClientError')}<br>Error: ${retryStatus.typeError}<br>${error?.description}`
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_RETRY_RESET" })
        }
    }, [retryStatus, dispatch, t])

    useEffect(() => {
        if (returnStatus) {
            switch (returnStatus?.message) {
                case "paymentRefundSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.paymentRefundSuccess')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                case "paymentRefundError":
                    const errorRefund = paycometErrors?.find(err => err.code === returnStatus?.typeError.toString())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        html: `${t('payments.paymentRefundError')}<br>Error: ${returnStatus.typeError}<br>${errorRefund?.description}`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                case "quatityRequired":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('payments.quantityRequired')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                case "paymentNotFound":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'error',
                        text: t('payments.paymentNotFound')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_RETURN_RESET" })
        }
    }, [returnStatus, dispatch, t])

    useEffect(() => {
        if (createManualStatus) {
            switch (createManualStatus?.message) {
                case "createPaymentSuccess":
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('payments.createManualPaymentSuccess')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                case "createPaymentError":
                    const errorCreate = paycometErrors?.find(err => err.code === createManualStatus?.typeError.toString())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        html: `${t('payments.createManualPaymentError')}<br>Error: ${createManualStatus.typeError}<br>${errorCreate?.description}`
                    }).then((result) => {
                        if (result.isConfirmed) {
                            closeModal()
                        }
                    })
                    break;
                default:
                    break;
            }
            dispatch({ type: "PAYMENT_CREATEMANUALPAYMENT_RESET" })
        }
    }, [createManualStatus, dispatch, t])

    const formatDateToHoursDay = (dateString) => {
        const date = parseISO(dateString)
        return format(date, 'HH:mm - dd/MM/yyyy')
    }

    const formatDateToDay = (dateString) => {
        const date = parseISO(dateString)
        return format(date, 'dd/MM/yyyy')
    }

    const handleSelectChange = (value) => {
        dispatch(editInscription(inscriptionselected, value, 'status', usertype))
    }

    const handleChange = (e, field) => {
        let newValue;

        if (field === 'horary') {
            newValue = e.target.value;
            setSelectedHorary(newValue);
        } else if (field === 'group') {
            newValue = e.target.value;
            setSelectedGroup(newValue);
        } else if (field === 'externalInitialPayment') {
            newValue = e.target.checked;
        }

        dispatch(editInscription(inscriptionselected, newValue, field, usertype));
    }

    const handleRegisterPaymentQuantityChange = (newQuantity) => {
        setRegisterPaymentQuantity(newQuantity);
        setRegisterPaymentQuantityTax(calculatePayment(newQuantity));

        // Lógica para actualizar la cantidad en el backend
        dispatch(editPaymentQuantity(inscription._id, 'initialPayment', newQuantity));
    }

    const handleQuantityChange = (index, newQuantity) => {
        console.log(newQuantity, index)
        const priceClient = calculatePayment(newQuantity)

        const updatedQuantities = [...quantities]
        updatedQuantities[index] = newQuantity
        setQuantities(updatedQuantities)

        const updatedQuantitiesTax = [...quantitiesTax]
        updatedQuantitiesTax[index] = priceClient
        setQuantitiesTax(updatedQuantitiesTax)

        // Lógica para actualizar la cantidad en el backend
        const paymentId = seasonPayments[index]._id;
        dispatch(editPaymentQuantity(paymentId, 'seasonPayment', newQuantity));
    }
    const handleQuantityPaymentChange = (newQuantity , paymentId) => {
        dispatch(editPaymentQuantity(paymentId, 'editPaymentQuantity', newQuantity));
    }

    const calculatePayment = (price) => {
        if (typeof price !== 'number') {
            price = parseFloat(price)
        }

        if (!isNaN(price)) {
            const activity = commission?.activities?.find(activity => price >= activity.lowPrice && price <= activity.highPrice);
            if (activity) {
                return price > 0 ? (Math.ceil(((price * (activity.percentage || 0) / 100) + (activity.double || 0)) * 100) / 100).toFixed(2) : parseFloat(price).toFixed(2)
            } else {
                return '0.00'
            }
        } else {
            return '0.00'
        }
    }

    const handleExternalPaymentChange = (index, value) => {
        const updatedExternalPayments = [...externalPayments]
        updatedExternalPayments[index] = value
        setExternalPayments(updatedExternalPayments)

        // Actualizamos valor en backend
        const paymentId = seasonPayments[index]._id;
        dispatch(editPaymentExternalStatus(paymentId, value));
    }
    const handlePaymentExternalPaymentChange = (paymentId, value) => {
        dispatch(editPaymentExternalStatus(paymentId, value));
    }

    const handleCreatePayment = (paymentClient,type,id,seasonPayment,rawQuantity) => {
        Swal.fire({
            showConfirmButton: true,
            showCancelButton: true,
            icon: 'warning',
            text: t('payments.createManualPaymentQuestion')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(createManualPayment({
                    paymentClient: paymentClient,
                    type: type,
                    ref: id,
                    seasonPaymentRef: seasonPayment,
                    rawQuantity: rawQuantity
                }))
            }
        })
    }

    return (
        <div>
            {inscription && !loadinginscription ?
                <>
                    {/* DATOS DE LA INSCRIPCIÓN */}
                    <Row className="mb-5">
                        <Col xs={12} lg={8}>
                            <div>
                            {usertype === 'superadmin' || usertype === 'club' ?
                                <>
                                    <div className='d-flex justify-content-between border-1 border-bottom  p-2'>
                                        <small>{t('inscriptions.client')}:</small>
                                        <div className='text-end'>
                                            {inscription?.client?.firstname+' '+inscription?.client?.lastname}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom  p-2'>
                                        <small>{t('users.phone')}:</small>
                                        <div className='text-end'>
                                            {inscription?.client?.phone ?
                                                <>
                                                    {inscription?.client?.phone}
                                                </>
                                            :
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between border-1 border-bottom  p-2'>
                                        <small>{t('users.email')}:</small>
                                        <div className='text-end'>
                                            {inscription?.clientEmail ?
                                                <>
                                                    {inscription?.clientEmail}
                                                </>
                                            :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </>
                            :
                                ''
                            }
                            {usertype === 'superadmin' || usertype === 'client' ?
                                <div className='d-flex justify-content-between border-1 border-bottom  p-2'>
                                    <small>{t('inscriptions.club')}:</small> {inscription?.club?.name}
                                </div>
                            :
                                ''
                            }
                            </div>
                            {(usertype === 'superadmin' || usertype === 'club') && typeform === 'edit' ?
                                <div className='border-1 border-bottom p-2'>
                                    <small>{t('inscriptions.group')}:</small>
                                    <br/>
                                    <select className="form-select font-small" value={selectedGroup || inscription?.group?._id} onChange={(e) => handleChange(e, 'group')}>
                                        {groups?.map(group => (
                                            <option key={group._id} value={group._id}>
                                                {group.title} ({group.activity?.title} / {group.category?.title})
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            :
                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                    <small>{t('inscriptions.group')}:</small>
                                    <small>
                                        {inscription?.group?.title}
                                    </small>
                                </div>
                            }
                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                <small>{t('inscriptions.activity')} / {t('inscriptions.category')}:</small>
                                {inscription?.activity?.title} / {inscription?.category?.title}
                            </div>
                            {(usertype === 'superadmin' || usertype === 'club') && typeform === 'edit' ?
                                <div className='border-1 border-bottom p-2'>
                                    <small>{t('inscriptions.horary')}:</small>
                                    <br/>
                                    <select className="form-select font-small" value={selectedHorary || inscription?.horary?._id} onChange={(e) => handleChange(e, 'horary')}>
                                        {inscription?.group?.horaries?.map(horary => (
                                            <option key={horary._id} value={horary._id}>
                                                {horary.name} - {t('inscriptions.gapsoccupied')}: {horary.occupiedGaps}/{horary.gaps}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            :
                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                    <small>{t('inscriptions.horary')}:</small>
                                    <small>
                                        {inscription?.horary?.name}
                                    </small>
                                </div>
                            }
                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                <small>{t('inscriptions.dates')}</small>
                                <small>
                                    {inscription?.startDate ? formatDateToDay(inscription?.startDate) : <></>}
                                    -
                                    {inscription?.endDate ? formatDateToDay(inscription?.endDate) : <></>}
                                </small>
                            </div>
                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                <small>{t('inscriptions.policy')}</small>
                                <small>
                                    {inscription.policy ? t('global.yes') : t('global.no')}
                                </small>
                            </div>
                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                <small>{t('inscriptions.minorsPolicy')}</small>
                                <small>
                                    {inscription.minorsPolicy ? t('global.yes') : t('global.no')}
                                </small>
                            </div>
                            <div className='d-flex justify-content-between p-2'>
                                <small>{t('inscriptions.sendMarketPolicy')}</small>
                                <small>
                                    {inscription.sendMarketPolicy ? t('global.yes') : t('global.no')}
                                </small>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            {(usertype === 'superadmin' || usertype === 'club') && typeform === "edit" ?
                                <div className='p-2 text-center'>
                                    <small>{t('inscriptions.status')}</small>
                                    <div className='fw-bold'>{t('inscriptions.status'+inscription?.status)}</div>
                                    {inscription?.status === "0" || inscription?.status === "4" ?
                                        <div className='mt-4'>
                                            <small>{t('inscriptions.changestatus')}</small>
                                            <div className='btn btn-secondary p-1 my-3' onClick={() => handleSelectChange("1")}>{t('inscriptions.toapprove')}</div>
                                            <div className='btn btn-default p-1' onClick={() => handleSelectChange("3")}>{t('inscriptions.tocancel')}</div>
                                        </div>
                                    :
                                        inscription?.status !== "3" ?
                                            <div className='mt-4'>
                                                <small>{t('inscriptions.changestatus')}</small>
                                                <div className='btn btn-default p-1 my-3' onClick={() => handleSelectChange("3")}>{t('inscriptions.tocancel')}</div>
                                            </div>
                                        :
                                            <div className='mt-4'>
                                                <small>{t('inscriptions.changestatus')}</small>
                                                <div className='btn btn-secondary p-1 my-3' onClick={() => handleSelectChange("1")}>{t('inscriptions.toapprove')}</div>
                                                <div className='btn btn-default p-1' onClick={() => handleSelectChange("0")}>{t('inscriptions.toverify')}</div>
                                            </div>
                                    }
                                </div>
                            :
                                <div className='p-2 text-center'>
                                    <small>{t('inscriptions.status')}</small>
                                    <div className='fw-bold'>{t('inscriptions.status'+inscription?.status)}</div>
                                </div>
                            }
                        </Col>
                    </Row>
                    {inscription?.questions?.length > 0 ?
                        <>
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-between align-items-end">
                                        <h3 className="font-single">{t('inscriptions.questions')}</h3>
                                    </div>
                                    <hr className="fina"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="striped">
                                    <Row className="my-2 p-2 rounded">
                                        <Col xs={12} lg={4}>
                                            {t('inscriptions.question')}
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            {t('inscriptions.answer')}
                                        </Col>
                                        <Col xs={12} lg={4}>
                                            {t('inscriptions.impact')}
                                        </Col>
                                    </Row>
                                    {inscription?.questions.map((question, index) => (
                                        <Row className="my-2 p-2 rounded" key={index}>
                                            <Col xs={12} lg={4}>
                                                {question.question}
                                            </Col>
                                            <Col xs={12} lg={4}>
                                                {question.answer}
                                            </Col>
                                            <Col xs={12} lg={4}>
                                                {t('inscriptions.impactregistration')}<br/>
                                                    {question?.impact?.registrationQuantity ? question?.impact?.registrationQuantity+' €' : '0 €'}
                                                    {question?.impact?.registrationQuantity && question?.impact?.registrationPercentage ? ' - ' : ''}
                                                    {question?.impact?.registrationPercentage ? question?.impact?.registrationPercentage+' %' : '0 %'}<br/><br/>
                                                {t('inscriptions.impactseason')}<br/>
                                                    {question?.impact?.seasonPaymentQuantity ? question?.impact?.seasonPaymentQuantity+' €' : '0 €'}
                                                    {question?.impact?.seasonPaymentQuantity && question?.impact?.seasonPaymentPercentage ? ' - ' : ''}
                                                    {question?.impact?.seasonPaymentPercentage ? question?.impact?.seasonPaymentPercentage+' %' : '0 %'}<br/>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    :
                        <></>
                    }
                    {/* PAGO INICIAL */}
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-end">
                                <h3 className="font-single">{t('inscriptions.initialpayment')}</h3>
                            </div>
                            <hr className="fina"/>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className='striped'>
                            <Row className="my-2 p-2 rounded sm">
                                <Col xs={12} lg={6}>
                                    <div className='d-flex justify-content-between flex-column border-1 border-bottom p-2'>
                                        <div className='d-flex justify-content-between'>
                                            <small>{t('inscriptions.quantity')}:</small>
                                            {(usertype === 'superadmin' || usertype === 'club') && typeform === "edit" ?
                                                <Form.Group className="form-group">
                                                    <Form.Control
                                                        className="has-content text-right p-1"
                                                        required
                                                        type="number"
                                                        name="price"
                                                        value={registerPaymentQuantity}
                                                        onChange={(e) => handleRegisterPaymentQuantityChange(e.target.value)}
                                                    />
                                                    <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                </Form.Group>
                                            :
                                                registerPaymentQuantity+' €'
                                            }
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <small>{t('inscriptions.priceclient')}</small>
                                            <div>{registerPaymentQuantityTax} €</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <small>{t('inscriptions.finalprice')}</small>
                                            <div>{(Number(registerPaymentQuantity) + Number(registerPaymentQuantityTax)).toFixed(2)} €</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} lg={6}>
                                    {usertype === 'superadmin' || usertype === "club" ?
                                    <>
                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                            <small>{t('payments.createManualPayment')}</small>
                                            {typeform === "edit" && !externalInitialPayment ?
                                                <Link className='btn btn-pagos-manuales ms-2'
                                                        onClick={(e) => handleCreatePayment(
                                                            inscription.paymentClient,
                                                            'Inscription',
                                                            inscription._id,
                                                            null,
                                                            registerPaymentQuantity
                                                        )}
                                                >
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip id="tooltip">
                                                                    {t('payments.createManualPayment')}
                                                                </Tooltip>}
                                                    >
                                                        <img className="cursor" src="/images/menus/payments.svg" alt="eyes" height={16}/>
                                                    </OverlayTrigger>
                                                </Link>
                                            :
                                            <></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-between flex-column border-1 border-bottom p-2'>
                                            <small>{t('payments.externalInitialPayment')}</small>
                                            {typeform === "edit" ?
                                                <>
                                                    <Form.Check
                                                        className="ms-3"
                                                        type="switch"
                                                        name="externalInitialPayment"
                                                        onChange={(e) => handleChange(e, 'externalInitialPayment')}
                                                        checked={externalInitialPayment || false}
                                                    />
                                                </>
                                            :
                                                externalInitialPayment ? t('global.yes') : t('global.no')
                                            }
                                        </div>
                                    </>
                                    :
                                        <Col xs={12} lg={6}>
                                            <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('payments.externalInitialPayment')}</small>
                                                {externalInitialPayment ? t('global.yes') : t('global.no')}
                                            </div>
                                        </Col>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* PAGOS RECURRENTES */}
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between align-items-end">
                                <h3 className="font-single">{t('inscriptions.seasonpayments')}</h3>
                            </div>
                            <hr className="fina"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="striped">
                            {seasonPayments && seasonPayments.length > 0 ?
                                seasonPayments?.map((seasonPayment, index) => (
                                    <Row className="my-2 p-2 rounded" key={index}>
                                        <Col xs={12} lg={6}>
                                            <div className='d-flex flex-column border-1 border-bottom p-2'>
                                                <div className='d-flex justify-content-between'>
                                                    <small>{t('inscriptions.quantity')}</small>
                                                        {(usertype === 'superadmin' || usertype === 'club') && typeform === "edit" ?
                                                            <Form.Group className="form-group">
                                                                <Form.Control
                                                                    className="has-content text-right p-1"
                                                                    required
                                                                    type="number"
                                                                    name="price"
                                                                    value={quantities[index]}
                                                                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                                />
                                                                <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                            </Form.Group>
                                                        :
                                                            quantities[index]+' €'
                                                        }

                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <small>{t('inscriptions.priceclient')}</small>
                                                    <div>{quantitiesTax[index]} €</div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <small>{t('inscriptions.finalprice')}</small>
                                                    <div>{(Number(quantities[index]) + Number(quantitiesTax[index])).toFixed(2)} €</div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between p-2'>
                                                <small>{t('inscriptions.paymentdate')}:</small>
                                                {seasonPayment?.date ? formatDateToDay(seasonPayment?.date) : ''}
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            {usertype === 'superadmin' || usertype === "club" ?
                                                <>
                                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                        <small>{t('payments.createManualPayment')}</small>
                                                        {typeform === "edit" && !externalPayments[index] ?
                                                            <Link className='btn btn-pagos-manuales ms-2'
                                                                    onClick={(e) => handleCreatePayment(
                                                                        inscription.paymentClient,
                                                                        'Inscription',
                                                                        inscription._id,
                                                                        seasonPayment._id,
                                                                        quantities[index]
                                                                    )}
                                                            >
                                                                <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip id="tooltip">
                                                                                {t('payments.createManualPayment')}
                                                                            </Tooltip>}
                                                                >
                                                                    <img className="cursor" src="/images/menus/payments.svg" alt="eyes" height={16}/>
                                                                </OverlayTrigger>
                                                            </Link>
                                                        :
                                                        <></>
                                                        }
                                                    </div>
                                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                        <small>{t('payments.externalPayment')}</small>
                                                        {typeform === "edit" ?
                                                            <>
                                                                <Form.Check
                                                                    className="ms-3"
                                                                    type="switch"
                                                                    name="verified"
                                                                    onChange={(e) => handleExternalPaymentChange(index, e.target.checked)}
                                                                    checked={externalPayments[index] || false}
                                                                />
                                                            </>
                                                        :
                                                            seasonPayment?.externalPayment || seasonPayment?.externalPaid ? t('global.yes') : t('global.no')
                                                        }
                                                    </div>
                                                </>
                                            :
                                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                    <small>{t('payments.externalPayment')}</small>
                                                    {seasonPayment?.externalPayment || seasonPayment?.externalPaid ? t('global.yes') : t('global.no')}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                ))
                            :
                                <small>{t('inscriptions.thereisnotseasonpayment')}</small>
                            }
                        </Col>
                    </Row>
                    {/* OPERACIONES BANCARIAS */}
                    <Container className='p-4 border-1 border' style={{backgroundColor:"#fefefe99"}}>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2>{t('inscriptions.bankingOperations')}</h2>
                                    {typeform === "edit"
                                    ?
                                        <Link className='btn btn-pagos-manuales'
                                                onClick={(e) => setCreatePaymentInscription(inscription)}
                                        >
                                            <OverlayTrigger placement="top"
                                                overlay={<Tooltip id="tooltip">
                                                            {t('payments.createManualPayment')}
                                                        </Tooltip>}
                                            >
                                                <img className="cursor" src="/images/pago-manual.png" alt="Pago manual" height={20}/>
                                            </OverlayTrigger>
                                        </Link>
                                    :
                                        <></>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr className="fina"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="striped">
                                {payments && payments.length > 0 ?
                                    payments?.map((paymentDone, index) => (
                                        <Row className="my-2 p-2 rounded" key={index}>
                                            <Col xs={12} lg={6}>
                                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                    <small>{t('inscriptions.paymentreference')}</small> {paymentDone?.paymentReference}
                                                </div>
                                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                <small>{t('inscriptions.quantity')}</small>
                                                    {(usertype === 'superadmin' || usertype === 'club') && paymentDone?.status === 0 && typeform === "edit" ?
                                                        <Form.Group className="form-group">
                                                            <Form.Control
                                                                className="has-content text-right p-1 pt-4"
                                                                required
                                                                type="number"
                                                                name="price"
                                                                value={paymentDone?.rawQuantity}
                                                                onChange={(e) => handleQuantityPaymentChange(e.target.value,paymentDone?._id)}
                                                            />
                                                            <img src="/images/money.svg" alt="Money" className="icono-money" />
                                                        </Form.Group>
                                                    :
                                                       <>{paymentDone?.quantity} €</>
                                                    }
                                                </div>
                                                {(usertype === 'superadmin' || usertype === 'club') && paymentDone?.status === 0 && typeform === "edit" &&
                                                    <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                        <small>{t('inscriptions.finalprice')}</small>
                                                        <div>{(paymentDone?.quantity).toFixed(2)} €</div>
                                                    </div>
                                                }
                                                <div className='d-flex justify-content-between p-2'>
                                                    <small>{t('inscriptions.paymentdate')}:</small>
                                                    {paymentDone?.paymentDate ? formatDateToHoursDay(paymentDone?.paymentDate) : ''}
                                                </div>
                                                {paymentDone?.description
                                                ?
                                                    <div className='d-flex justify-content-between p-2'>
                                                        <small>{t('inscriptions.description')}:</small>
                                                        {paymentDone?.description}
                                                    </div>
                                                :
                                                <></>
                                                }
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                    <div><small>{t('inscriptions.status')}:</small></div>
                                                    <div className='d-flex justify-content-end align-items-center'>
                                                        {paymentDone?.externalPayment || paymentDone?.externalPaid
                                                        ?
                                                            t('inscriptions.statusseasonpay3')
                                                        :
                                                        <>
                                                            <strong>{t('inscriptions.statusseasonpay' + paymentDone?.status)}</strong>
                                                            {(usertype === 'superadmin' || usertype === 'club') && (
                                                                <>
                                                                    {
                                                                        (paymentDone.status === 1 || paymentDone.status === 0) && (
                                                                            <Link onClick={() => setManualPaymentSelected(paymentDone)} className='btn btn-pagos ms-2'>
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                                {t('payments.manualPayment')}
                                                                                            </Tooltip>}
                                                                                >
                                                                                    <img className="cursor" src="/images/menus/payments.svg" alt="eyes" height={16}/>
                                                                                </OverlayTrigger>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                    {
                                                                        (paymentDone.status === 3 && !paymentDone.sepaPaid) && (
                                                                            <Link onClick={() => setPaymentToReturnSelected(paymentDone)} className='btn btn-pagos ms-2'>
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip id="tooltip">
                                                                                                {t('payments.returnPayment')}
                                                                                            </Tooltip>}
                                                                                >
                                                                                    <img className="cursor" src="/images/money-return.svg" alt="eyes" height={16}/>
                                                                                </OverlayTrigger>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </>
                                                                )
                                                            }
                                                        </>
                                                        }

                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                    <small>{t('inscriptions.creationdate')}:</small>
                                                    {paymentDone?.creationDate ? formatDateToHoursDay(paymentDone?.creationDate) : ''}
                                                </div>
                                                {usertype === 'superadmin' || usertype === 'club' ?
                                                    <div className='d-flex justify-content-between p-2'>
                                                        <small>{t('inscriptions.sepapaid')}</small> {paymentDone?.sepaPaid ? t('global.yes') : t('global.no')}
                                                    </div>
                                                :
                                                    <></>
                                                }
                                                {
                                                    usertype === 'superadmin' || usertype === "club"
                                                    ?
                                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                            <small>{t('payments.externalPayment')}</small>
                                                            {
                                                                typeform === "edit" && paymentDone?.status === 0
                                                                ?
                                                                <>
                                                                    <Form.Check
                                                                        className="ms-3"
                                                                        type="switch"
                                                                        name="verified"
                                                                        onChange={(e) => handlePaymentExternalPaymentChange(paymentDone._id, e.target.checked)}
                                                                        checked={paymentDone?.externalPaid || false}
                                                                    />
                                                                </>
                                                                :
                                                                    paymentDone?.externalPayment || paymentDone?.externalPaid ? t('global.yes') : t('global.no')
                                                            }
                                                        </div>
                                                    :
                                                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                                                            <small>{t('payments.externalPayment')}</small>
                                                            {paymentDone?.externalPayment || paymentDone?.externalPaid ? t('global.yes') : t('global.no')}
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                    ))
                                :
                                    <small>{t('inscriptions.thereisnotseasonpayment')}</small>
                                }
                            </Col>
                        </Row>
                    </Container>
                    <ManualPayment userType="club" payment={manualPaymentSelected} onHide={() => setManualPaymentSelected(null)} inscriptionBank={true}/>
                    <ReturnPayment userType="club" payment={paymentToReturnSelected} onHide={() => setPaymentToReturnSelected(null)} inscriptionBank={true}/>
                    <CreateManualPayment userType='club' inscription={createPaymentInscription} onHide={() => setCreatePaymentInscription(null)} />
                </>
            :
                <Loader/>
            }
        </div>
	)
}