import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { getAvailableMatchesByClub } from '../../actions/ticketing/matchesActions'
import { getFeesByClub } from '../../actions/ticketing/feesActions.js'
import { getSectorsByClub, getSectorUnavailableSeats } from '../../actions/ticketing/sectorsActions'
import Select from "react-select"
import Loader from '../design/Loader/Loader'
import { createWindowTicket, getTicketsByClub } from '../../actions/ticketing/ticketsActions'
import Swal from 'sweetalert2'
import SectorMap from './SectorMap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MultipleTickets from '../downloads/MultipleTickets.js'

export default function WindowTicket({show, closeModal, club}) {
  	const { t } = useTranslation()
    const dispatch = useDispatch()

    const {loading: matchesLoading, matches} = useSelector(state => state.matches)
    const {loading: feesLoading, fees} = useSelector(state => state.fees)
    const {loading: sectorsLoading, sectors, unavailableSeats} = useSelector(state => state.sectors)
    const {loading, result, newTickets} = useSelector(state => state.ticket)

    const [windowTicketForm, setWindowTicketForm] = useState({
        match: null,
        sector: null,
        personData: {
            firstname: null,
            lastname: null,
            email: null,
            phone: null
        },
        tickets: [{
            seat: {
                row: null,
                column: null
            },
            fee: null,
            price: null
        }]
    })

    const handleCloseModal = () => {
        dispatch({ type: "TICKET_NEW_TICKETS_RESET" })
        setWindowTicketForm({
            match: null,
            sector: null,
            personData: {
                firstname: null,
                lastname: null,
                email: null,
                phone: null
            },
            tickets: [{
                seat: {
                    row: null,
                    column: null
                },
                fee: null,
                price: null
            }]
        })
        closeModal()
    }

    const handleInputChange = ({target}) => {
        if (target.name === "match") {
            setWindowTicketForm({
                ...windowTicketForm,
                [target.name]: target.value,
                sector: null,
                tickets: windowTicketForm.tickets.map(ticket => {
                    return {
                        seat: {
                            row: null,
                            column: null
                        },
                        fee: null,
                        price: null
                    }
                })
            })
        } else if (target.name === "sector") {
            dispatch(getSectorUnavailableSeats(target.value, windowTicketForm.match))
            setWindowTicketForm({
                ...windowTicketForm,
                [target.name]: target.value,
                tickets: windowTicketForm.tickets.map(ticket => {
                    return {
                        seat: {
                            row: null,
                            column: null
                        },
                        fee: null,
                        price: null
                    }
                })
            })
        } else {
            setWindowTicketForm({
                ...windowTicketForm,
                [target.name]: target.value
            })
        }
    }

    const handleTicketFeeChange = (index, value) => {
        setWindowTicketForm({
            ...windowTicketForm,
            tickets: windowTicketForm.tickets.map((ticket, ticketIndex) => {
                if (ticketIndex === index) {
                    return {
                        ...ticket,
                        fee: value,
                        price: matches?.find(m => m._id === windowTicketForm.match)?.sectors?.find(s => s.sector === windowTicketForm.sector)?.fees?.find(f => f.fee === value)?.price || 0.00
                    }
                } else {
                    return ticket
                }
            })
        })
    }

    const handlePersonDataInputChange = ({target}) => {
        setWindowTicketForm({
            ...windowTicketForm,
            personData: {
                ...windowTicketForm.personData,
                [target.name]: target.value
            }
        })
    }

    const handleSeatClick = (index, row, column)=> {
        setWindowTicketForm({
            ...windowTicketForm,
            tickets: windowTicketForm.tickets.map((ticket, ticketIndex) => {
                if (index === ticketIndex) {
                    return {
                        ...ticket,
                        seat: {
                            row,
                            column
                        }
                    }
                } else {
                    return ticket
                }
            })
        })
    }

    const handleAddTicket = () => {
        setWindowTicketForm({
            ...windowTicketForm,
            tickets: [
                ...windowTicketForm.tickets,
                {
                    seat: {
                        row: null,
                        column: null
                    },
                    fee: null,
                    price: null
                }
            ]
        })
    }

    const removeTicket = () => {
        let ticketsCopy = [...windowTicketForm.tickets]
        if (ticketsCopy.length > 1) {
            ticketsCopy.splice(ticketsCopy.length - 1, 1)
            setWindowTicketForm({
                ...windowTicketForm,
                tickets: ticketsCopy
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!windowTicketForm.match || !windowTicketForm.sector || !windowTicketForm.personData.firstname || windowTicketForm.personData.firstname === "" || !windowTicketForm.personData.email || windowTicketForm.personData.email === "") {
            Swal.fire({
                icon: 'error',
                text: t('matches.ticket.createWindowTicketFormError'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        } else {
            let seatSelectedLeft = false
            let feeLeft = false
            for (const ticket of windowTicketForm.tickets) {
                if (sectors?.find(s => s._id === windowTicketForm.sector)?.numerated) {
                    if (ticket.seat.row === null && !seatSelectedLeft) {
                        seatSelectedLeft = true
                    }
                }

                if (!ticket.fee) {
                    feeLeft = true
                }
            }

            if (!seatSelectedLeft) {
                if (!feeLeft) {
                    dispatch(createWindowTicket({...windowTicketForm, club: club._id}))
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: t('matches.ticket.feeLeft'),
                        showConfirmButton: true,
                        confirmButtonText: t('global.accept'),
                        timer: 2000
                    })    
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    text: t('matches.ticket.seatLeft'),
                    showConfirmButton: true,
                    confirmButtonText: t('global.accept'),
                    timer: 2000
                })
            }
        }
    }

    useEffect(() => {
        if (club) {
            dispatch(getAvailableMatchesByClub(club._id))
            dispatch(getSectorsByClub(club._id))
            dispatch(getFeesByClub(club._id))
        }
    }, [club, dispatch])

    useEffect(() => {
        if (result) {
            let newResult = result
            dispatch({
                type: 'TICKET_CHANGE_STATUS_RESET'
            })
            switch (newResult) {
                case 'createWindowTicketSuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('matches.ticket.createWindowTicketSuccess')
                    }).then(() => {
                        dispatch(getTicketsByClub(club._id))
                    })
                    break;
                case 'createWindowTicketFormError':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.ok'),
                        icon: 'error',
                        text: t('matches.ticket.createWindowTicketFormError')
                    })
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [result, dispatch, t, club])

    return (
        <Modal show={show} onHide={handleCloseModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>{t('tickets.addWindowTicket')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body className='cuerpo px-5 py-4'>
                    {
                        matchesLoading || sectorsLoading || feesLoading
                        ?
                            <Loader/>
                        :
                            <>
                                <Row>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='match'
                                                options={matches.map(match => {
                                                    return { label: `${match.host} - ${match.visitor} ${new Date(match.date).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${match.hour}`, value: match._id}
                                                })}
                                                value={windowTicketForm.match ? {value: windowTicketForm.match, label: `${matches.find(m => m._id === windowTicketForm.match)?.host} - ${matches.find(m => m._id === windowTicketForm.match)?.visitor} ${new Date(matches.find(m => m._id === windowTicketForm.match)?.date || new Date()).toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})} ${matches.find(m => m._id === windowTicketForm.match)?.hour}`} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'match', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='match' className='px-3'>{t('matches.ticket.match')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='firstname'
                                                value={windowTicketForm.personData.firstname || ''}
                                                onChange={handlePersonDataInputChange}
                                                required
                                                className={windowTicketForm.personData.firstname ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='firstname' className='px-3'>{t('matches.ticket.firstname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='lastname'
                                                value={windowTicketForm.personData.lastname || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={windowTicketForm.personData.lastname ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='lastname' className='px-3'>{t('matches.ticket.lastname')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='email'
                                                required
                                                value={windowTicketForm.personData.email || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={windowTicketForm.personData.email ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='email' className='px-3'>{t('matches.ticket.email')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Form.Control
                                                type='text'
                                                name='phone'
                                                value={windowTicketForm.personData.phone || ''}
                                                onChange={handlePersonDataInputChange}
                                                className={windowTicketForm.personData.phone ? 'has-content' : ''}
                                            />
                                            <Form.Label htmlFor='phone' className='px-3'>{t('matches.ticket.phone')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end'>
                                    <Col xs={12} className='my-3'>
                                        <Form.Group className='form-group'>
                                            <Select
                                                className='form-control has-content'
                                                name='sector'
                                                options={matches.find(m => m._id === windowTicketForm.match)?.sectors?.map(sector => {
                                                    const currentSector = sectors.find(s => s._id === sector.sector)
                                                    if (currentSector.deleted === true || sector.active === false || sector.maxCapacity < 1) {
                                                        return null
                                                    } else {
                                                        return { value: sector.sector, label: currentSector.name }
                                                    }
                                                })?.filter(v => v !== null) || []}
                                                value={windowTicketForm.sector ? {value: windowTicketForm.sector, label: sectors.find(s => s._id === windowTicketForm.sector)?.name} : null}
                                                onChange={(value) => {
                                                    handleInputChange({target: { name: 'sector', value: value.value }})
                                                }}
                                            />
                                            <Form.Label htmlFor='sector' className='px-3'>{t('matches.ticket.sector')}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {
                                    windowTicketForm.tickets.map((ticket, ticketIndex) => (
                                        <Card className='my-3' key={ticketIndex}>
                                            <Card.Body> 
                                                <Row className='align-items-end'>
                                                    <Col xs={12} className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Select
                                                                className='form-control has-content'
                                                                name='fee'
                                                                options={matches.find(m => m._id === windowTicketForm.match)?.sectors?.find(s => s.sector === windowTicketForm.sector)?.fees.map(fee => {
                                                                    const currentFee = fees.find(s => s._id === fee.fee)
                                                                    if (currentFee.deleted === true || fee.active === false) {
                                                                        return null
                                                                    } else {
                                                                        return { value: fee.fee, label: currentFee.name }
                                                                    }
                                                                })?.filter(v => v !== null) || []}
                                                                value={ticket?.fee ? {value: ticket?.fee, label: fees.find(s => s._id === ticket?.fee)?.name} : null}
                                                                onChange={(value) => {
                                                                    handleTicketFeeChange(ticketIndex, value.value)
                                                                }}
                                                            />
                                                            <Form.Label htmlFor='fee' className='px-3'>{t('matches.ticket.fee')}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {
                                                    sectors?.find(s => s._id === windowTicketForm.sector)?.numerated &&
                                                    <SectorMap
                                                        rows={sectors?.find(s => s._id === windowTicketForm.sector)?.distribution?.rows}
                                                        columns={sectors?.find(s => s._id === windowTicketForm.sector)?.distribution?.columns}
                                                        unavailable={unavailableSeats?.unavailable?.map(s => {
                                                            return [s[0] + 1, s[1] + 1]
                                                        }).concat(windowTicketForm.tickets.map((ticket, index) => {
                                                            if (index === ticketIndex) {
                                                                return null
                                                            } else {
                                                                return [ticket.seat.row + 1, ticket.seat.column + 1]
                                                            }
                                                        })).filter(s => s !== null)}
                                                        deleted={unavailableSeats?.deleted?.map(s => {
                                                            return [s[0] + 1, s[1] + 1]
                                                        })}
                                                        selected={windowTicketForm?.tickets[ticketIndex]?.seat?.row !== null ? [windowTicketForm?.tickets[ticketIndex]?.seat.row + 1, windowTicketForm?.tickets[ticketIndex]?.seat.column + 1] : null}
                                                        action={(row, column) => {handleSeatClick(ticketIndex, row, column)}}
                                                    />
                                                }
                                                <Row>
                                                    <Col xs={4} className='offset-8 py-3 fw-bold border rounded group-price text-center'>
                                                        {ticket.price || 0.00} €
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    ))
                                }
                                <Row>
                                    {
                                        parseInt(matches.find(m => m._id === windowTicketForm.match)?.sectors?.find(s => s.sector === windowTicketForm.sector)?.maxCapacity || 0) - windowTicketForm.tickets.length > 0 &&
                                        <Col xs={12} md={6} className='my-2'>
                                            <div className='d-grid'>
                                                <Button type='button' variant='primary' className='btn-sm p-2' onClick={handleAddTicket}>
                                                    <i className='fas fa-plus'/> {t("matches.addTicket")}
                                                </Button>
                                            </div>
                                        </Col>
                                    }
                                    {
                                        windowTicketForm.tickets.length > 1 &&
                                        <Col xs={12} md={6} className='my-2'>
                                            <div className='d-grid'>
                                                <Button type='button' variant='outline-primary' className='btn-sm p-2' onClick={removeTicket}>
                                                    <i className='fas fa-x'/> {t("matches.removeLastTicket")}
                                                </Button>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {
                                    windowTicketForm.sector &&
                                    <div className="text-primary">
                                        <small>
                                        {t("matches.ticket.seatsLeft", {seatsLeft: parseInt(matches.find(m => m._id === windowTicketForm.match)?.sectors?.find(s => s.sector === windowTicketForm.sector)?.maxCapacity || 0) - windowTicketForm.tickets.length})}
                                        </small>
                                    </div>
                                }
                                <Row className='mt-3'>
                                    <Col xs={4} className='offset-8 py-3 fw-bold border rounded group-price text-center'>
                                        {windowTicketForm.tickets.reduce((sum, ticket) => {
                                            if (ticket.price && ticket.price > 0) {
                                                return parseFloat(sum) + parseFloat(ticket.price)
                                            } else {
                                                return parseFloat(sum)
                                            }
                                        }, 0).toFixed(2)} €
                                    </Col>
                                </Row>
                            </>
                    }
                    {
                        newTickets &&
                        <PDFDownloadLink document={<MultipleTickets tickets={newTickets}/>} fileName={`${newTickets.reduce((sum, ticket) => { return sum + '-' + ticket.identifier }, '')}.pdf`}>
                            <Button type='button' className='w-100 my-2' variant='default'>
                                <img src='/images/download.svg' alt='download'/>
                            </Button>
                        </PDFDownloadLink>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type='button' variant='default' onClick={handleCloseModal}>{t('global.cancel')}</Button>
                    <Button type='submit' variant='primary'>
                        {
                            loading
                            ?
                                <Loader/>
                            :
                                t('global.save')
                        }
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
	)
}