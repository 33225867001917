import React, { Fragment, useEffect, useState } from 'react'
import MenuMarketplace from '../../components/menus/MenuMarketplace'
import { Alert, Button, Card, Col, Container, Form, FormSelect, Modal, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { getClient } from '../../actions/profiles/clientsActions'
import Loader from '../../components/design/Loader/Loader'
import { getPaymentStatus, payItems, startPublicPurchase, startPurchase } from '../../actions/payments/paymentActions'
import Swal from 'sweetalert2'
import { resetCart } from '../../actions/payments/cartActions'
import { useNavigate } from 'react-router'
import { FooterMarketplace } from '../../components/footers/FooterMarketplace'
import CheckOutActivity from '../../components/Activities/CheckOutActivity'
import CheckOutPassType from '../../components/ticketing/CheckOutPassType'
import CheckOutTicket from '../../components/ticketing/CheckOutTicket'
import CheckOutReservation from '../../components/ticketing/CheckOutReservation'
export default function CheckOut() {
	const {clientauth} = useAuth()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [policy, setPolicy] = useState(false)
    const [policyMinors, setPolicyMinors] = useState(false)
    const [policySendMarket, setPolicySendMarket] = useState(false)

    const { items } = useSelector(state => state.cart)
    const { client } = useSelector(state => state.clients)
    const { loading, error, paymentData, paymentStatus, itemsPayment, publicPurchaseData } = useSelector(state => state.payment)

    // const [total, setTotal] = useState(null)
    const [totalToPay, setTotalToPay] = useState(null)
    const [totalSaved, setTotalSaved] = useState(null)
    const [totalCommission, setTotalCommision] = useState(null)

    // If any group requires verification
    // const [needVerification, setNeedVerification] = useState(false)

    const [waitingForPayment, setWaitingForPayment] = useState(false)

    const [isPaying, setIsPaying] = useState(false)

    const [selectedCard, setSelectedCard] = useState(null)

    const [paymentPersonData, setPaymentPersonData] = useState({
        firstname: null,
        lastname: null,
        email: null
    })

    const handlePayClick = () => {
        if (client) {
            if (!client?.cards || client?.cards?.length === 0) {
                dispatch(startPurchase({
                    quantity: 0,
                    paymentClient: client?._id,
                    product: t('client.newCardDescription'),
                }))
            }
        }
        setIsPaying(true)
    }

    const handlePaymentLinkClick = () => {
        setWaitingForPayment(true)

        const intervalFn = () => {
            clearInterval(window.checkOutPaymentStatusInterval)
            setWaitingForPayment(false)
            setIsPaying(false)
            dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
        }

        window.checkOutPaymentStatusIntervalIndex = 0

        window.checkOutPaymentStatusInterval = setInterval(() => {
            window.checkOutPaymentStatusIntervalIndex++
            dispatch(getPaymentStatus(client ? paymentData?.payment?._id : publicPurchaseData?.payment?._id))
            if (window.checkOutPaymentStatusIntervalIndex > 600) {
                intervalFn()
            }
        }, 1000)
    }

    const saveCart = () => {
        dispatch(payItems({items, card: selectedCard, paymentClient: clientauth?.client, policy: policy, minorsPolicy: policyMinors, sendMarketPolicy: policySendMarket}))
    }

    const handlePaymentPersonDataChange = ({target}) => {
        setPaymentPersonData({
            ...paymentPersonData,
            [target.name]: target.value
        })
    }

    const handleStartPurchase = () => {
        if (!paymentPersonData.firstname || paymentPersonData.firstname === "" || !paymentPersonData.lastname || paymentPersonData.lastname === "" || !paymentPersonData.email || paymentPersonData.email === "") {
            Swal.fire({
                icon: 'error',
                text: t('cart.paymentPersonData.noFields'),
                showConfirmButton: true,
                confirmButtonText: t('global.accept'),
                timer: 2000
            })
        } else {
            dispatch(startPublicPurchase({items, paymentPersonData: paymentPersonData, policy: policy, minorsPolicy: policyMinors, sendMarketPolicy: policySendMarket}))
        }
    }

    useEffect(() => {
        // let acumulated = 0
        let acumulatedToPay = 0
        let totalCommission = 0
        let totalSaved = 0

        for (const item of items) {
            if (item.activity) {
                let priceToPay = 0
                let saved = 0
                let isInRangeToEnroll = false
                const currentDate = new Date()
    
                let registrationPayment = 0
                if (item.group.registrationPayment?.maxdate && new Date(item.group.registrationPayment?.maxdate) >= currentDate) {
                    registrationPayment = parseFloat(item.group.registrationPayment.price)
                }
                const registrationImpact = item.questions
                .reduce((sum, question) => {
                    const registrationPercentageTotal = (parseFloat(registrationPayment) * ((parseFloat(question?.impact?.registrationPercentage || 0)) / 100))
    
                    return parseFloat(sum) + (parseFloat(registrationPercentageTotal)) + (question?.impact?.registrationQuantity || 0);
                }, 0)
    
                if (item.promotions) {
                    let registrationTotal = parseFloat(registrationPayment) + parseFloat(registrationImpact)
                    for (const promotion of item.promotions) {
                        if (registrationTotal > 0) {
                            const promotionPercentageTotal = (parseFloat(registrationTotal) * ((parseFloat(promotion.impact.registrationPercentage || 0)) / 100))
                            registrationTotal -= (parseFloat(promotionPercentageTotal)) + (promotion.impact.registrationQuantity || 0)
                        } else {
                            registrationTotal = 0
                        }
                    }
    
                    if (registrationTotal < 0) {
                        registrationTotal = 0
                    }
    
                    saved += (parseFloat(registrationPayment) + parseFloat(registrationImpact)) - registrationTotal
                }
    
                const seasonPaymentsTotal = item.group.seasonPayments.reduce((seasonPaymentTotal, seasonPayment) => {
                    if (new Date(seasonPayment.startDate) <= currentDate && new Date(seasonPayment.endDate) >= currentDate) {
                        isInRangeToEnroll = true
                        const seasonPaymentImpact = item.questions
                        .reduce((questionTotal, question) => {
                            const seasonPaymentPercentageTotal = (parseFloat(seasonPayment.price) * ((parseFloat(question?.impact?.seasonPaymentPercentage || 0)) / 100))
            
                            return parseFloat(questionTotal) + (parseFloat(seasonPaymentPercentageTotal)) + (question?.impact?.seasonPaymentQuantity || 0);
                        }, 0)
    
                        let total = parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact)
    
                        if (item.promotions) {
                            for (const promotion of item.promotions) {
                                if (total > 0) {
                                    const promotionPercentageTotal = (parseFloat(total) * ((parseFloat(promotion.impact.seasonPaymentPercentage || 0)) / 100))
                                    total -= (parseFloat(promotionPercentageTotal)) + parseFloat(promotion.impact.seasonPaymentQuantity || 0)
                                } else {
                                    total = 0
                                }
                            }
    
                            if (total < 0) {
                                total = 0
                            }
                        }
    
                        saved += (parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact)) - total
                        return parseFloat(seasonPaymentTotal) + (parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact) <= 0 ? 0 : parseFloat(seasonPayment.price) + parseFloat(seasonPaymentImpact))
                    } else {
                        if (currentDate < new Date(seasonPayment.endDate)) {
                            isInRangeToEnroll = true
                        }
                        return seasonPaymentTotal
                    }
                }, 0)
    
                priceToPay = (parseFloat(registrationPayment) + parseFloat(registrationImpact) + parseFloat(seasonPaymentsTotal)) - saved
    
                const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
    
                // acumulated += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * parseFloat(priceComission?.percentage) / 100) + parseFloat(priceComission?.double)) * 100) / 100
    
                if (!item.group.verify && isInRangeToEnroll) {
                    if(!item.waitinglist){
                        if (priceToPay > 0) {
                            totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                            acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                        }
                    }
                }
                totalSaved += parseFloat(saved)
            }
            if (item.passType) {
                let priceToPay = item.passType.seasons?.find(season => season.season?._id === item.season)?.price || 0

                if (priceToPay > 0) {
                    const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
                    totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                    acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                }
            }
            if (item.match) {
                let priceToPay = 0

                if (item.ticket?.type === "pass") {
                    priceToPay = item.match.passTypes?.find(p => p.passType === item.ticket.client?.passType)?.price || 0
                } else {
                    priceToPay = item.match.sectors?.find(s => s.sector === item.ticket.sector._id)?.fees?.find(f => f.fee === item.ticket.fee._id)?.price || 0
                }

                if (priceToPay > 0) {
                    const priceComission = item.commission?.find(activity => priceToPay >= activity.lowPrice && priceToPay <= activity.highPrice);
                    totalCommission += Math.ceil((parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                    acumulatedToPay += Math.ceil((parseFloat(priceToPay) + parseFloat(priceToPay * (priceComission?.percentage || 0) / 100) + parseFloat(priceComission?.double || 0)) * 100) / 100
                }
            }
            if (item.court) {
                if (item.slots && item.slots.length > 0) {
                    let sum = 0
                    item.slots.map((value) => {
                        let price = value?.price
                        if (price > 0) {
                            let commission, fixedFee;
                            if (price < 10) {
                                commission = 0.035;
                                fixedFee = 0.09;
                            } else if (price >= 10 && price <= 29.99) {
                                commission = 0.0289;
                                fixedFee = 0.05;
                            } else if (price >= 30) {
                                commission = 0.0245;
                                fixedFee = 0;
                            }
                            const commissionAmount = Math.ceil((price * commission + fixedFee) * 100) / 100;
                            const totalPrice = price + commissionAmount;
                            totalCommission += commissionAmount;
                            acumulatedToPay += Math.round(totalPrice * 100) / 100;
                            return parseFloat(sum) + Math.round(totalPrice * 100) / 100;
                        } else {
                            return sum;
                        }
                    });
                }
            }
        }

        // setTotal(acumulated)
        setTotalToPay(acumulatedToPay)
        setTotalCommision(totalCommission)
        setTotalSaved(totalSaved)
    }, [items])

    useEffect(() => {
        if (clientauth?.client) {
            dispatch(getClient(clientauth?.client))
        }
    }, [clientauth, dispatch])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if (client && client.cards) {
            let defaultCard = client.cards?.find(c => c.favourite === true) || client.cards[0]
            if (defaultCard) {
                setSelectedCard(defaultCard._id)
            }
        }
    }, [client])

    useEffect(() => {
        if (paymentStatus && paymentStatus !== 0) {
            clearInterval(window.checkOutPaymentStatusInterval)
            setWaitingForPayment(false)
            setIsPaying(false)
            dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
            if (paymentStatus === 3) {
                if (paymentData) {
                    dispatch(getClient(clientauth?.client))
                    dispatch({ type: 'PAYMENT_PAYMENT_DATA_RESET' })
                }

                if (clientauth) {
                    if (itemsPayment) {
                        dispatch({ type: 'PAYMENT_PAYMENT_REQUEST_RESET' })
                        dispatch(resetCart())
                        Swal.fire({
                            showConfirmButton: true,
                            icon: 'success',
                            text: t('cart.paymentSuccess')
                        })
                        sessionStorage.setItem('dataIncrono', JSON.stringify({...JSON.parse(sessionStorage.getItem('dataIncrono')), cartId: itemsPayment.cart._id, cartReference: itemsPayment.cart.cartReference}))
                        navigate('/' + t("url.marketplace.finishedCart"))
                    }
                } else {
                    dispatch({ type: 'PAYMENT_PAYMENT_REQUEST_RESET' })
                    dispatch(resetCart())
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'success',
                        text: t('cart.paymentSuccess')
                    })
                    sessionStorage.setItem('dataIncrono', JSON.stringify({...JSON.parse(sessionStorage.getItem('dataIncrono')), cartId: publicPurchaseData.cart._id, cartReference: publicPurchaseData.cart.cartReference}))
                    dispatch({ type: "PAYMENT_START_PUBLIC_PURCHASE_RESET" })
                    setPaymentPersonData({
                        firstname: null,
                        lastname: null,
                        email: null
                    })
                    navigate('/' + t("url.marketplace.finishedCart"))
                }

            } else {
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: t('cart.paymentError')
                })
            }
        }
    }, [paymentStatus, dispatch, clientauth, paymentData, itemsPayment, t, navigate, publicPurchaseData])

    useEffect(() => {
        if (itemsPayment?.payment === true) {
            clearInterval(window.checkOutPaymentStatusInterval)
            setWaitingForPayment(false)
            setIsPaying(false)
            dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
            dispatch({ type: 'PAYMENT_PAYMENT_REQUEST_RESET' })
            dispatch(resetCart())
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('cart.paymentSuccess')
            })
            sessionStorage.setItem('dataIncrono', JSON.stringify({...JSON.parse(sessionStorage.getItem('dataIncrono')), cartId: itemsPayment.cart._id, cartReference: itemsPayment.cart.cartReference}))
            navigate('/' + t("url.marketplace.finishedCart"))
        } else if (itemsPayment?.payment) {
            setWaitingForPayment(true)

            const intervalFn = () => {
                clearInterval(window.checkOutPaymentStatusInterval)
                setWaitingForPayment(false)
                setIsPaying(false)
                dispatch({ type: 'PAYMENT_PAYMENT_STATUS_RESET' })
            }

            window.checkOutPaymentStatusIntervalIndex = 0

            window.checkOutPaymentStatusInterval = setInterval(() => {
                window.checkOutPaymentStatusIntervalIndex++
                dispatch(getPaymentStatus(itemsPayment?.payment?._id))
                if (window.checkOutPaymentStatusIntervalIndex > 600) {
                    intervalFn()
                }
            }, 1000)
        }
    }, [itemsPayment, dispatch, t, navigate])

    return (
        <>
            <MenuMarketplace/>
            <Container className='market'>
                <div>
                    <Card className='border-0'>
                        <Card.Body>
                            <h2>{t("cart.finishCart")}</h2>
                            <hr className="mb-4"/>
                            {
                                items?.map((item, index) => (
                                    item.activity
                                    ?
                                        <CheckOutActivity item={item} key={index}/>
                                    :
                                        item.passType
                                        ?
                                            <CheckOutPassType key={index} item={item}/>
                                        :
                                            item.match
                                            ?
                                                <CheckOutTicket key={index} item={item}/>
                                            :
                                                item.court
                                                ?
                                                    <CheckOutReservation key={index} item={item}/>
                                                :
                                                    <Fragment key={index}/>
                                ))
                            }
                            <Row className='mt-3 align-items-end'>
                                {/* {
                                    needVerification &&
                                        <Col xs={12} className='mb-5'>
                                            <span className='text-muted'>* {t('cart.groupNeedVerificationText')}</span>
                                        </Col>
                                } */}
                                <Col xs={12}>
                                    <Col className='d-flex align-items-start fw-light'>
                                        <Form.Check className='float-left pe-3' type='checkbox' name='policy' onChange={(e) => setPolicy(e.target.checked)} checked={policy || false} />
                                        <p className="small mb-0">
                                            {t('users.policy1')}
                                            <a href="/politica-privacidad" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy2')}</u></a>
                                            {t('users.policy3')}
                                            <a href="/devoluciones" className='link' target='_blank' rel="noreferrer"><u>{t('users.policy4')}</u></a>
                                        </p>
                                    </Col>
                                    <Col className='d-flex align-items-start fw-light'>
                                        <Form.Check className='float-left pe-3' type='checkbox' name='minors' onChange={(e) => setPolicyMinors(e.target.checked)} checked={policyMinors || false} />
                                        <div className="small mb-0">
                                            {t('users.policyMinors')}
                                        </div>
                                    </Col>
                                    <Col className='d-flex align-items-start fw-light'>
                                        <Form.Check className='float-left pe-3' type='checkbox' name='sendmarket' onChange={(e) => setPolicySendMarket(e.target.checked)} checked={policySendMarket || false} />
                                        <p className="small">
                                            {t('users.policySendMarket')}
                                        </p>
                                    </Col>
                                    <Col className='fw-light'>
                                        <p style={{fontSize:'11px'}}>
                                            {t('users.policyInfo')}
                                            <a href="/politica-privacidad" className='link' target='_blank' rel="noreferrer"><u>+Info</u></a>
                                        </p>
                                    </Col>
                                </Col>
                                <Col xs={12} md={8}>
                                    {/* <div className='d-flex align-items-end my-3'>
                                        <h2 className='me-3 mb-0'>{t('cart.total')}:</h2>
                                        <span>{total?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</span>
                                    </div> */}
                                    <h3 className='me-3 mb-0 font-single'>{t('cart.totalToPay')}:
                                        <span className='ps-3'>{totalToPay?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</span>
                                    </h3>

                                    {
                                    totalCommission && totalCommission > 0
                                        ?
                                            <small>
                                                ({t('cart.includeAdministrationFees', {
                                                  totalCommission: totalCommission?.toLocaleString(undefined, {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2
                                                })
                                              })})
                                            </small>
                                        :
                                            <></>
                                    }

                                    {
                                        totalSaved && totalSaved > 0
                                        ?
                                            <h5 className='me-3 mb-0 font-single'>{t('cart.totalSaved')}:
                                                <span className='ps-3'>{totalSaved?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})}€</span>
                                            </h5>
                                        :
                                            <></>
                                    }
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className='d-grid'>
                                        <Button variant='primary' type='button' onClick={() => handlePayClick()} disabled={!policy}>
                                            {
                                                waitingForPayment
                                                    ?
                                                        <Loader className=''/>
                                                    :
                                                        t('cart.enroll')
                                            }
                                        </Button>
                                    </div>
                                </Col>
                                <hr className='my-1'/>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
            <Modal show={isPaying && !loading && !waitingForPayment} onHide={() => setIsPaying(false)} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{t('cart.paymentInformation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading || waitingForPayment
                            ?
                                <Loader/>
                            :
                                <Row>
                                    {
                                        client
                                        ?
                                            <>
                                                {
                                                    paymentData?.challengeUrl &&
                                                        <Col xs={12}>
                                                            <div className='mb-5'>{t('cart.needToRegisterCard')}</div>
                                                            <div className='d-grid'>
                                                                <a href={paymentData.challengeUrl} target='_blank' className='btn btn-primary' rel="noreferrer" onClick={handlePaymentLinkClick}>{t('cart.saveCard')}</a>
                                                            </div>
                                                        </Col>
                                                }
                                                {
                                                    client?.cards?.length > 0 &&
                                                        <Col xs={12}>
                                                        <div className='mb-5'>
                                                            <Form.Label htmlFor='selectedCard'>{t('cart.selectCard')}</Form.Label>
                                                            <FormSelect name='selectedCard' id='selectedCard' onChange={({target}) => setSelectedCard(target.value)} value={selectedCard || client.cards[0]._id}>
                                                                {
                                                                    client.cards.map(card => (
                                                                        <option key={card._id} value={card._id}>{card.name}</option>
                                                                    ))
                                                                }
                                                            </FormSelect>
                                                        </div>
                                                        <div className='d-grid mt-5'>
                                                            <Button type='button' variant='primary' onClick={saveCart}>{t('cart.pay')}</Button>
                                                        </div>
                                                        </Col>
                                                }
                                            </>
                                        :
                                            publicPurchaseData
                                            ?
                                                <Col xs={12}>
                                                    <div className='d-grid'>
                                                        <a href={publicPurchaseData.challengeUrl} target='_blank' className='btn btn-primary' rel="noreferrer" onClick={handlePaymentLinkClick}>{t('cart.pay')}</a>
                                                    </div>
                                                </Col>
                                            :
                                                <Col xs={12} className='cuerpo'>
                                                    <div className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name="firstname"
                                                                value={paymentPersonData.firstname || ""}
                                                                className={paymentPersonData.firstname ? 'has-content' : ''}
                                                                onChange={handlePaymentPersonDataChange}
                                                            />
                                                            <Form.Label htmlFor='firstname'>{t('cart.paymentPersonData.firstname')}</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name="lastname"
                                                                value={paymentPersonData.lastname || ""}
                                                                className={paymentPersonData.lastname ? 'has-content' : ''}
                                                                onChange={handlePaymentPersonDataChange}
                                                            />
                                                            <Form.Label htmlFor='lastname'>{t('cart.paymentPersonData.lastname')}</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='my-3'>
                                                        <Form.Group className='form-group'>
                                                            <Form.Control
                                                                type='text'
                                                                name="email"
                                                                value={paymentPersonData.email || ""}
                                                                className={paymentPersonData.email ? 'has-content' : ''}
                                                                onChange={handlePaymentPersonDataChange}
                                                            />
                                                            <Form.Label htmlFor='email'>{t('cart.paymentPersonData.email')}</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='d-grid mt-5'>
                                                        <Button type='button' variant='primary' onClick={handleStartPurchase}>{t('global.continue')}</Button>
                                                    </div>
                                                </Col>
                                    }
                                </Row>
                    }
                    {
                        error &&
                            <Alert variant='danger'>{ t('cart.' + error) }</Alert>
                    }
                </Modal.Body>
            </Modal>
            <FooterMarketplace />
        </>
    )
}