import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../../context/auth/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import SportsComponent from '../../../../components/sports'
import ServicesComponent from '../../../../components/services'
import { getClubs, getClubsByUser } from '../../../../actions/profiles/clubsActions'
import SiteForm from '../../../../components/forms/sites/SiteForm'
import provinces from "../../../../components/provinces.json"
import { addSite, editSite, getSite } from '../../../../actions/sites/sitesActions'
import ClubBreadCrumbs from '../../../../components/breadcrumbs/ClubBreadCrumbs'

export default function AddSite({usertype, handleSiteSelect}) {
  	const { t } = useTranslation()
	const dispatch = useDispatch()
    const navigate = useNavigate()
    const {clientauth} = useAuth()

	const {club, clubs} = useSelector(state=>state.clubs)

    const {result, loading, site} = useSelector(state => state.sites)

	const {sports} = SportsComponent()
    const {services} = ServicesComponent()

    const [sportsAux, setSportsAux] = useState([])
    const [servicesAux, setServicesAux] = useState([])

    const [newSite, setNewSite] = useState()

    const {siteId} = JSON.parse(sessionStorage.getItem("dataIncrono"))

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(club){
            if(clientauth?.usertype === 'superadmin'){
                dispatch(getClubs(false))
            }else{
                dispatch(getClubsByUser(clientauth.id))
            }
        }
    //eslint-disable-next-line
    }, [dispatch, clientauth, club])

    useEffect(()=>{
        async function setSiteInfo(site) {
            let sportsSaved = []
            let servicesSaved = []

            for await (const sport of site.sports) {
                sportsSaved.push(sports.find(s => s.value === sport))
            }

            for (const service of site.services) {
                servicesSaved.push(services.find(s => s.value === service))
            }
            
            setNewSite({
                name: site.name,
                sports: sportsSaved,
                image: site.image || null,
                club: {value: club._id, label: club.name},
                province: provinces.find(p => p.value === site.province) || null,
                town: site.town,
                address: site.address,
                services: servicesSaved,
                location: site.location,
                cancelDays: site.cancelDays,
                days: site.schedule.length > 0 ? site.schedule : [{weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}],
                datesBlocked: site.datesBlocked.map(dB => {
                    return {...dB, startDate: new Date(dB.startDate), endDate: new Date(dB.endDate)}
                }),
                deletedDays: [],
                deletedDatesBlocked: [],
                active: site.active
            })
        }

        if (siteId && site && club) {
            setSiteInfo(site.site)
        } else {
            if(club){
                setNewSite({
                    name: '',
                    sports: [],
                    image: null,
                    club: {value: club._id, label: club.name},
                    province: null,
                    town: '',
                    address: '',
                    services: [],
                    location: '',
                    cancelDays: 1,
                    days: [
                        {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null},
                    ],
                    datesBlocked: [],
                    deletedDays: [],
                    deletedDatesBlocked: [],
                    active: true
                })
            }
        }
        setSportsAux(sports)
        setServicesAux(services)
    //eslint-disable-next-line
    }, [club, site, siteId])

    useEffect(() => {
        if (siteId) {
            dispatch(getSite(siteId))
        }
    }, [siteId, dispatch])

    const handleInputChange = ({target})=>{
        setNewSite({
            ...newSite,
            [target.name]: target.value
        })
    }

    const handleCheckboxChange = ({target}) => {
        setNewSite({
            ...newSite,
            [target.name]: target.checked
        })
    }

    const handleSelectChange = (target, selectType) => {
        setNewSite({
            ...newSite,
            [selectType]: target
        })
    }

    const handleImage = (e)=>{
        setNewSite({
            ...newSite,
            image: e.target.files[0]
        })
    }

    const handleDayCheck = (target, index) => {
        setNewSite({
            ...newSite,
            days: newSite?.days?.map((day, i) => {
                if (i === index) {
                    if (target.checked) {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: [...day.weekDays, target.name]
                            }
                        } else {
                            return {
                                ...day,
                                weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                            }
                        }
                    } else {
                        if (target.name !== 'all') {
                            return {
                                ...day,
                                weekDays: day.weekDays.filter(d => (d !== target.name && d !== "all"))
                            } 
                        } else {
                            return {
                                ...day,
                                weekDays: []
                            }
                        }
                    }
                } else {
                    return day
                }
            })
        })
    }

    const addSchedule = () => {
        setNewSite({
            ...newSite,
            days: [...newSite.days, {weekDays: ['all', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], startHour: null, endHour: null}]
        })
    }

    const removeSchedule = (day, index) => {
        let deletedScheduleId = newSite.days.find((d, i) => i === index)._id
        setNewSite({
            ...newSite,
            days: newSite.days.filter((d, i) => i !== index),
            deletedDays: deletedScheduleId ? [...newSite.deletedDays, deletedScheduleId] : newSite.deletedDays
        })
    }

    const handleScheduleInputChange = (target, day) => {
        setNewSite({
            ...newSite,
            days: newSite.days.map(d => {
                if (d.weekDays === day) {
                    return {
                        ...d,
                        [target.name]: target.value
                    }
                } else {
                    return d
                }
            })
        })
    }

    const handleAdd = async(e) => {
        e.preventDefault()

        if(newSite.name !== '' || newSite.address === '' || !newSite.province || newSite.town !== ""){
            let sportsToSend = newSite.sports?.map(sport => {
                return sport.value
            }) || []

            let servicesToSend = newSite.services?.map(service => {
                return service.value
            })

            if (!siteId) {
                dispatch(addSite({...newSite, club: newSite.club.value, province: newSite.province.value, sports: sportsToSend, services: servicesToSend}))
            } else {
                dispatch(editSite({...newSite, club: newSite.club.value, province: newSite.province.value, sports: sportsToSend, services: servicesToSend}, siteId))
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                showDenyButton: false,
                confirmButtonText: t('global.ok'),
                icon: 'warning',
                text: t('sites.formNotFinished')
            })
        }
    }

    useEffect(() => {
        if(result?.message){
            switch (result.message) {
                case 'success':
                    handleSiteSelect(result.site?._id)
                    dispatch({
                        type: 'SITE_RESET_RESULT'
                    })
                    if(usertype === 'superadmin'){
                        navigate(`/${t("url.admin.admin")}/${t("url.admin.club")}/${t("url.admin.sites")}/${t("url.admin.edit")}/${t("url.admin.step2")}`)
                    }else{
                        navigate(`/${t("url.club.club")}/${t("url.club.sites")}/${t("url.club.edit")}/${t("url.club.step2")}`)
                    }
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [result])

	return (
        <Container className='cuerpo'>
            <ClubBreadCrumbs usertype={usertype} club={club} />
            <Row className="my-3">
                <Col>
                    <h1 className='ms-2 my-0'>{t('sites.sites')}</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <h2 className='fs-18'>{t('sites.createSite')}</h2>
                            <hr className='mb-4'/>
                            <SiteForm state={newSite} clubs={clubs} statetype={'add'} usertype={usertype} provinces={provinces} isAdding={loading} services={servicesAux} sports={sportsAux} submit={handleAdd} inputChange={handleInputChange} checkboxChange={handleCheckboxChange} selectChange={handleSelectChange} handleImage={handleImage}  addSchedule={addSchedule} removeSchedule={removeSchedule} handleDayCheck={handleDayCheck} handleScheduleInputChange={handleScheduleInputChange}/>
                        </Card.Body>
                    </Card>
                </Col>                
            </Row>
        </Container>
	)
}